@keyframes moveBackground {
  0% { background-position: 0 0; }
  100% { background-position: 0 100%; }
}

.gameContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px; /* Add some space at the top */
}

.gameContainer::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Make sure this is below the content */
  background: url('../public/assets/background.png') repeat-y;
  background-size: cover;
  animation: moveBackground 50s linear infinite;
}

.score {
  margin-top: 3em
}
.score, .distance {
  z-index: 10; /* Make sure this is higher than other elements */
  color: #fff;
  font-size: 1.5em; /* Adjust if needed */
  text-shadow: 2px 2px 4px #000000;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  border-radius: 10px;
  min-width: 150px; /* Ensure a minimum width */ 
}

